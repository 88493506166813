import { Component, OnInit } from '@angular/core';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PopupService } from 'src/app/core/services/popup.service';
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';

// import Auth from '@aws-amplify/auth';
// import { Analytics } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';

// const amplifyConfig = {
//   Auth: {
//     identityPoolId: 'us-east-1:280abb4f-8eea-4a15-a64c-fb313fcc326e',
//     region: 'us-east-1'
//   }
// }

//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
//   AWSPinpoint: {
//     appId: '2e2afc99918a42f9bf32ea1e8b0a164c',
//     region: 'us-east-1',
//     mandatorySignIn: false,
//   }
// }

// Analytics.configure(analyticsConfig)

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public viewLoading: boolean = false;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public email: any;
  public fName: string;
  public lName: string;
  public fullName: string;
  public imgUrl: any;
  public phone: any;
  public gender: any;
  public locality: any;
  public school: any;
  public ethnicity: any;
  public picture: any;
  public personalInfo: boolean = false;
  public parentInfo: boolean = true;
  public badgeInfo = false;
  public totalCoin: number = null;
  public badges = [];
  public currentBadge: any = {};
  public showDullNumber;
  public isParent = false;
  public date: any;
  public dob: any;
  public roleName: any;
  public childArray: any = [];
  public childPic: any;
  public role: number = null;
  public insightResult: Array<any> = [];
  public profileStatus: boolean;
  public localityBoolean: boolean = true;
  public currentRolee: any;
  public list3;
  //social variables
  public total_point: number = null;
  public rankContact: number = null;
  public rankSchool: number = null;
  public rankSpotivity: number = null;
  public pitTaken:any;
  public newResult:any;
  public profileComplete:any;
  public notiCount:any;
  constructor(
    private appService: AppService,public translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    private popupService: PopupService) {
    window.scroll(0, 0)
  }

  ngOnInit() {
    this.getProfile();
    this.getEQPQResult();
    this.getTotal();
    this.getPopup('');
    this.getNotificationsCount();
    // Analytics.record({ name: 'Profile Visited' });
  }

  calculateDullStatus() {
    for (let i = 0; i < this.badges.length; i++) {
      if (this.badges[i]._id === this.currentBadge._id) {
        this.showDullNumber = i;
      }
    }
  }
  getProfile() {
    this.viewLoading = true;
    this.insightResult = [];
    this.appService.get(urls.getProfile).subscribe((response: any) => {
      if (response && response.sc === 1) {
        this.viewLoading = false;
        localStorage.setItem('loginRole', response.result.user.role);
        //login role 1 = parent and 2 = child
        localStorage.setItem('linkedin', response.result.user.connect_by_linkedin);
        localStorage.setItem('instagram', response.result.user.connect_by_inst);
        localStorage.setItem('twitter', response.result.user.connect_by_twit);
        //set the role which logged in user can add
        localStorage.setItem('canAddUserRoleType', response.result.user.role === 1 ? 'Child' : 'Parent');

        // set badges and current badge
        this.isParent = response.result.user.role === 1;

        this.badges = response.result.badges;
        this.currentBadge = response.result.current_badge;
        this.calculateDullStatus();
        this.date = new Date(response.result.user.dob * 1000);
        this.dob = (this.date.getMonth() + 1) + '/' + this.date.getDate() + '/' + this.date.getFullYear();
        this.email = response.result.user.email;
        this.fName = response.result.user.first_name;
        this.lName = response.result.user.last_name;
        this.phone = response.result.user.phone_no;
        this.currentRolee = response.result.user.role;
        this.profileStatus = response.result.user.profile_completed;
        if (
          response.result.user.locality
          &&
          response.result.user.flat
          &&
          response.result.user.locality
          &&
          (response.result.user.city ? response.result.user.city.name : false)
          &&
          (response.result.user.state ? response.result.user.state.name : false)
          &&
          response.result.user.zipcode
        ) {
          this.localityBoolean = true;
        } else {
          this.localityBoolean = false;
        }
        this.locality =
          (response.result.user.flat ? response.result.user.flat + ',' : '') + ' ' +
          (response.result.user.locality ? response.result.user.locality + ',' : '') + ' ' +
          (response.result.user.city ? response.result.user.city.name + ',' : '') + ' ' +
          (response.result.user.state ? response.result.user.state.name + ',' : '') + ' ' +
          (response.result.user.zipcode ? response.result.user.zipcode + ',' : '');
        if (response.result.user.gender == 1) {
          this.gender = 'Male';
        }
        if (response.result.user.gender == 2) {
          this.gender = 'Female';
        }
        if (response.result.user.gender == 3) {
          this.gender = 'Neither';
        }
        if (response.result.user.gender === 2) {
          this.gender = 'Female';
        }
        if (response.result.user.gender === 3) {
          this.gender = 'Neither';
        }
        this.school = (response.result.user.school) ? response.result.user.school.school_name : '';
        this.fullName = this.fName + ' ' + this.lName;
        this.roleName = (response.result.user.role === 1) ? 'Child' : 'Parents/Guardians';
        this.picture = (response.result.user.profile_pic) ? response.result.user.profile_pic : '';
        this.childArray = [];
        this.role = response.result.user.role;
        this.getProgress();
        this.insightResult = response.result.insight_result;
        this.total_point = response.result.user.userSpotivityRank;
      }
    }, (error) => {
      this.viewLoading = false;
      console.log(error);
    });
  }
  goToParent() {
    this.router.navigate(['/parent/search-parent']);
  }

  routeTo(route) {
    if (route === 'wallet') {
      this.router.navigate(['/wallet']);
    } else if (route === 'leadership') {
      this.router.navigate(['wallet/leader-board']);
    }
  }

  getProfilePic() {
    let dummyImg = './assets/img/blank-image.png';
    let st: any;
    if (this.imgUrl) {
      st = { background: `url(${this.imgUrl || dummyImg})` };
    } else if (this.picture) {
      st = { background: `url(${this.picture})` };
    } else {
      st = { background: `url(${dummyImg})` };
    }
    return st;
  }
  openInsight() {
    this.getPopup('insight');
  }

  getPopup(popupType): void {
    this.popupService.showPopup(popupType);
  }
  getTotal() {
    this.appService.post(urls.getTotalPoint)
      .subscribe((res: any) => {
        this.totalCoin = res.result.Total_Coin;
        this.rankSpotivity = res.result.rank_by_spotivity;
      })
  }
  goToLinkedProviders(){
    this.router.navigate(['/user/linked-agencies'],{queryParams:{'navFor':'providers'}})
  }
  goToFiles(){
    this.router.navigate(['/user/linked-agencies'],{queryParams:{'navFor':'files'}})
  }
  getEQPQResult(){
    this.viewLoading = true;
    this.appService.get(urls.getResult).subscribe((res:any)=>{
      if(res['result']){
          this.pitTaken = res['result'].canTakePIT;
      }
      this.viewLoading = false;
    }
    )
  }
  getProgress(){
    this.viewLoading = true;
    this.appService.get(urls.getProgressData).subscribe((res:any)=>{
      if(res['result'] && res['result'].newResult){
          this.newResult = res['result'].newResult;
          this.profileComplete = Math.trunc((this.newResult['num']/this.newResult['deno'])*100)
      }
      this.viewLoading = false;
    }
    )
  }
  getNotificationsCount(){
    this.appService.get(urls.getNotificationCount).subscribe((response:any)=>{
               if(response && response['result']){
                        this.notiCount = response['result'].count;
               }
    })
}
}
