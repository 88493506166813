import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { AppService } from '../../../../shared/services/app.service';
import { urls, defaultLocation } from '../../../../shared/services/constant';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { of as observableOf } from 'rxjs';
import { map, filter, throttleTime, delay } from 'rxjs/operators';
import { AuthServiceService } from '../../../../core/services/authService/auth-service.service';
import { PopupService } from '../../../../core/services/popup.service';
import { Options } from '@angular-slider/ngx-slider';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-map-view',
    templateUrl: './map-view.component.html',
    styleUrls: ['./map-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MapViewComponent implements OnInit {
    public showFiler: boolean = false;
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public latitude: any;
    public longitude: any;
    public programArray: any = [];
    public searchArray: any = [];
    public season: any;
    public springSeason: string = '';
    public winterSeason: string = '';
    public fallSeason: string = '';
    public summerSeason: string = '';
    public resultsScrollObservable: any;
    public scrollSubscription: any;
    public page_no: number = 1;
    public locIndex: any
    public lastElementData: any;
    public program_type: number = 3;
    public premium_type: any = 2;
    public lasTimestamp: number = 0;
    public faIconClass: string = 'fa fa-search';
    public showNextData = true;
    public bucketArray: any = [];
    public checkedBucket: any = [];
    public checkedProgram: any = [];
    public checkedDays: any = [];
    public showNoResultFound: boolean = false;
    public seasonData: any = '';
    public hideProgramUl: boolean = false;
    public type: any = '';
    public locationList: any = [];
    public isClicked: boolean = false;
    public isUserLoggedIn: boolean = true;
    public modal: any;
    public currentRolee: any;
    public currentLocation: string = "Current Location";
    public value: number = 10;
    public options: Options = {
        floor: 0,
        ceil: 100,
        showSelectionBarFromValue: 0
    };
    public skip = 1;
    public skipS = 1;
    public limit = 10;
    public keywordsArr = [];
    public keywords: any = [];
    public timeout: any = null;
    public searchKey: string = '';
    public show: boolean = false;
    public filterCount:number = 2;
    public filtercount:string = "2";
    public radius: any = 10;
    public viewLoading: boolean = false;
    public mode: any;
    public selSeason:string = "";
    constructor(private appService: AppService,
        private router: Router,
        private formBuilder: FormBuilder,public translate: TranslateService,
        private authService: AuthServiceService,
        private popupService: PopupService,private cd: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute) {

        this.activatedRoute.queryParams.subscribe(params => {
            this.type = params['type'];
            if (this.type === 'refersh') {
                let clean_uri = location.protocol + '//' + location.host + location.pathname;
                window.history.replaceState({}, document.title, clean_uri);
                this.page_no = 1;
                this.lasTimestamp = 0;
                this.premium_type = 2;
                this.programArray = [];
                this.getProgramList();
            }
        });
    }

    mapFilterForm: FormGroup;

    ngOnInit() {
        this.fetchLocation();
        this.mapFilterForm = this.formBuilder.group({
            season: [''],
            bucketList: [''],
            pType: [true],
            searchProgram: [''],
            search: [''],
            filter_program_type: this.formBuilder.array([]),
            bucket: this.formBuilder.array([]),
            days: [''],
            daysArr: this.formBuilder.array([]),
            time: [''],
            radius: 10,
            location: ['current'],
        });
        this.programChange('1', true);
        this.programChange('2', true);
        this.isLoggedIn();

        observableOf(null).pipe(delay(400)).subscribe(() => {
            window.scrollTo(0, 0);
            this.getProgramList();

        });

        this.resultsScrollObservable = fromEvent(window, 'scroll');
        /**
         * Captures scrolling behavior within teh application, so we can implement infinite
         *  scroll or hiding elements once a certain amount of scrolling is done.
         */
        this.scrollSubscription = this.resultsScrollObservable
            .pipe(
                throttleTime(250),
                map((event: any) => {
                    if (event) {
                        const elem = event.srcElement ? event.srcElement : event.target;
                        const srcElement = elem.body;
                        const clientHeight = srcElement ? srcElement.clientHeight : 0;
                        const el = document.scrollingElement || document.documentElement;
                        const scrollTop = el.scrollTop;
                        const position = scrollTop + clientHeight;
                        const height = srcElement ? srcElement.scrollHeight : 0;
                        const positionPercent = +((position / height).toFixed(2));
                        return positionPercent > .85;
                    }
                }),
                filter((doSearch: boolean) => doSearch)
            )
            .subscribe((doSearch: boolean) => {
                if (this.showNextData) {
                    this.page_no = this.page_no + 1;
                    this.getProgramList();
                }

            });
    }

    get f() {
        return this.mapFilterForm.controls;
    }

    programChange(ptype: string, isChecked: boolean) {
        const programFormArray = <FormArray>this.mapFilterForm.controls.filter_program_type;

        if (isChecked) {
            programFormArray.push(new FormControl(ptype));
            this.filterCount = this.filterCount + 1
            if(this.filterCount < 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        } else {
            let index = programFormArray.controls.findIndex(x => x.value === ptype);
            programFormArray.removeAt(index);
            this.filterCount = this.filterCount - 1
            if(this.filterCount < 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }

    dayChange(dtype: any, isChecked: boolean) {
        const dayFormArray = <FormArray>this.mapFilterForm.controls.daysArr;

        if (isChecked) {
            dayFormArray.push(new FormControl(dtype));
            this.filterCount = this.filterCount + 1
            if(this.filterCount < 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        } else {
            let index = dayFormArray.controls.findIndex(x => x.value === dtype);
            dayFormArray.removeAt(index);
            this.filterCount = this.filterCount - 1
            if(this.filterCount < 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }

    bucketChange(btype: string, isChecked: boolean) {
        const bucketFormArray = <FormArray>this.mapFilterForm.controls.bucket;
        if (isChecked) {
            bucketFormArray.push(new FormControl(btype));
            this.filterCount = this.filterCount + 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
        else {
            let index = bucketFormArray.controls.findIndex(x => x.value == btype)
            bucketFormArray.removeAt(index);
            this.filterCount = this.filterCount - 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }

    bucketFirstChange(btype: string, isChecked: boolean) {
        const bucketFormArray = <FormArray>this.mapFilterForm.controls.bucket;
        if (isChecked) {
            bucketFormArray.push(new FormControl(btype));
        }
    }

    onSubmit(params) {
        window.scroll(0,0);
        let data = Object.assign({}, params);
        this.checkedDays = data.daysArr;
        this.checkedBucket = data.bucket;
        this.checkedProgram = data.filter_program_type;
        if (this.checkedProgram.length > 1 || this.checkedProgram[0] === '2') {
            this.premium_type = 2;
        } else {
            this.premium_type = 1;
        }
        this.seasonData = data.season;
        this.page_no = 1;
        this.program_type = 3;
        this.programArray = [];
        this.radius = this.value;
        this.getProgramList();
    }

    getProgramList() {
        if (!this.latitude || !this.longitude) {
            this.latitude = defaultLocation.latitude
            this.longitude = defaultLocation.longitude
        }
        this.apiParam.appJson = true;
        let params = {
            'latitude': this.latitude,
            'longitude': this.longitude,
            'timestamp': this.lasTimestamp,
            'limit': 10,
            'program_type': this.program_type,
            'page_no': this.page_no,
            'premium_type': this.premium_type,
            'bucket': this.checkedBucket,
            'filter_program_type': this.checkedProgram,
            'season': this.seasonData,
            'sort': 1
        };
        params['radius'] = this.radius;
        if (this.checkedDays && this.checkedDays.length) {
            params['daysOfWeek'] = this.checkedDays;
        }
        if(this.keywords && this.keywords.length){
            params['keywords'] = this.keywords
        }
        this.apiParam.params = params;
        this.apiParam.appJson = true;
        this.appService.post(urls.programList, this.apiParam.params)
            .subscribe(
                (response: any) => {
                    this.showNextData = false;
                    if (response.sc === 1 && response.result.program) {
                        let ProgramData = response.result.program;
                        if (ProgramData && ProgramData.length > 0) {
                            ProgramData.forEach((ele, ind) => {
                                if (ele.spring_season === 1) {
                                    this.springSeason = 'Spring, ';
                                }
                                if (ele.winter_season === 1) {
                                    this.winterSeason = 'Winter, ';
                                }
                                if (ele.fall_season === 1) {
                                    this.fallSeason = 'Fall, ';
                                }
                                if (ele.summer_season === 1) {
                                    this.summerSeason = 'Summer,';
                                }
                                this.season = this.springSeason + this.winterSeason + this.fallSeason + this.summerSeason;
                                this.season = this.season.slice(0, -1);

                                let obj = {
                                    'name': ele.program_name,
                                    'image': ele.image__url,
                                    'address': ele.address,
                                    'season': this.season,
                                    'agency': ele.agency_name,
                                    'id': ele._id,
                                    'favorite': ele.is_favourite,
                                    'program_type': ele.program_type,
                                    'free': ele.free,
                                    'buckets': ele.buckets
                                };
                                this.programArray.push(obj);
                                this.lastElementData = ProgramData[ProgramData.length - 1];
                                if (this.lastElementData.program_type === 3) {
                                    this.program_type = this.lastElementData.program_type;
                                    this.premium_type = (this.lastElementData.free === 0) ? this.lastElementData.paid : this.lastElementData.free;

                                } else {
                                    this.premium_type = 0;
                                }
                                this.lasTimestamp = this.lastElementData.created_at;
                            });
                            this.showNextData = true;
                            this.cd.detectChanges();
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    mapFilter() {
        if (this.faIconClass === 'fa fa-search') {
            this.faIconClass = 'fa fa-search';
            if (this.bucketArray.length == 0) {
                this.viewLoading = true;
                this.getBucketList();
                this.getKeywordList(this.skip, this.limit);
                this.getUserProfile();
            }
        } else {
            this.faIconClass = 'fa fa-search';
        }
        this.showFiler = !this.showFiler;
    }
    getKeywordList(skip, limit) {
        let params = {};
        this.show = false;
        this.apiParam.appJson = true;
        skip = skip ? ((skip - 1) * 10) : skip;
        this.appService.get(urls.listKeyword + `?skip=${skip}&limit=${limit}`)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        if (response.result.keywords && response.result.keywords.length > 0) {
                            response.result.keywords.forEach((ele, ind) => {
                                let obj = {
                                    'name': ele.keyword,
                                    'id': ele._id,
                                };
                                this.keywordsArr.push(obj);
                                this.cd.detectChanges();
                                this.viewLoading = false;
                            });
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    checkForKeywords(keyword: any) {
        if (this.keywords.length > 0) {
            for (let i = 0; i <= this.keywords.length - 1; i++) {
                if (this.keywords[i] == keyword.name) {
                    return true;
                }
            }
        }
    }
    change(event, name) {
        if (event.target.checked == true) {
            if(this.keywords.length<5){
                this.keywords.push(name)
                this.filterCount = this.filterCount + 1
                if(this.filterCount <= 10){
                    this.filtercount = this.filterCount.toString();
                  }
                  else if(this.filterCount > 10 && this.filterCount < 20){
                      this.filtercount = "10+"
                  }
                  else if(this.filterCount == 20){
                    this.filtercount = this.filterCount.toString();
                  }
                  else if(this.filterCount > 20){
                      this.filtercount = "20+"
                  }
            }
            else{
             event.target.checked = false;
             this.appService.showInfo('Only 5 keywords can be applied on filters')
            }      
        }
        else {
            const index = this.keywords.indexOf(name);
            this.keywords.splice(index, 1);
            this.filterCount = this.filterCount - 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }
    removeKeyword(name){
        const index = this.keywords.indexOf(name);
        this.keywords.splice(index, 1);
        this.filterCount = this.filterCount - 1
        if(this.filterCount <= 10){
            this.filtercount = this.filterCount.toString();
          }
          else if(this.filterCount > 10 && this.filterCount < 20){
              this.filtercount = "10+"
          }
          else if(this.filterCount == 20){
            this.filtercount = this.filterCount.toString();
          }
          else if(this.filterCount > 20){
              this.filtercount = "20+"
          }
    }
    loadAllOnScroll(event) {
        const elem = event.target;
        if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
            if (this.searchKey == '') {
                this.skip = this.skip + 1;
                this.getKeywordList(this.skip, this.limit);
            }
            else {
                this.skipS = this.skipS + 1;
                this.searchKeyword(this.skipS, this.limit, this.searchKey)
            }
        }
        else{

        }
    }
    searchKeyword(skip, limit, keyword) {
        let params = {};
        this.apiParam.appJson = true;
        skip = skip ? ((skip - 1) * 10) : skip;
        this.appService.get(urls.listKeyword + `?skip=${skip}&limit=${limit}&name=${keyword}`)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        this.show = false;
                        if (response.result.keywords && response.result.keywords.length > 0) {
                            response.result.keywords.forEach((ele, ind) => {
                                let obj = {
                                    'name': ele.keyword,
                                    'id': ele._id,
                                };
                                this.keywordsArr.push(obj);
                                this.cd.detectChanges();
                                this.viewLoading = false;
                            });
                        }
                        else {
                            if (this.keywordsArr.length == 0) {
                                this.show = true;
                            }
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    onKey(event) {
        this.searchKey = event
        clearTimeout(this.timeout);
        this.keywordsArr = [];
        var $this = this;
        this.timeout = setTimeout(function () {
            if (event.keyCode != 13) {
                this.mode = document.getElementById("keydrop").classList.add("show");
                if (event.length > 2) {
                    this.skipS = 1;
                    this.limit = 10;
                    $this.searchKeyword(this.skipS, this.limit, event)
                }
                else {
                    this.skip = 1;
                    this.limit = 10;
                    $this.getKeywordList(this.skip, this.limit);
                }
            }
        }, 1000);
    }
    isLoggedIn() {
        let user = localStorage.getItem('LoggedIn');
        if (user && user.length) {
            this.isUserLoggedIn = true
        }
        else {
            this.isUserLoggedIn = false
        }
    }

    programDetail(id) {
        if (this.isUserLoggedIn)
            this.router.navigate(['program/program-detail/' + id]);
        else {
            this.getPopup('onboarding');
            this.popupService.popupVisibilityChange.subscribe(value => {
                if (this.popupService.isPopupVisible) {
                    this.popupService.togglePopupVisibility(false);
                    this.router.navigate(['/']);
                }
            });
        }
    }

    getBucketList() {
        let params = {};
        this.apiParam.appJson = true;
        this.appService.post(urls.listBucket, params)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        this.bucketArray = [];
                        if (response.result.bucket && response.result.bucket.length > 0) {
                            response.result.bucket.forEach((ele, ind) => {
                                let obj = {
                                    'icon': ele.icon,
                                    'image': ele.image,
                                    'name': ele.name,
                                    'id': ele._id,
                                };
                                this.bucketArray.push(obj);
                                this.cd.detectChanges();
                                this.viewLoading = false;
                            });
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    test() {
    }

    onSearchChange(data) {
        this.showNoResultFound = false;
        if (data.length >= 3) {
            this.searchProgram(data);
        } else {
            this.searchArray = [];
        }
    }

    searchProgram(data) {
        this.hideProgramUl = true;
        this.apiParam.appJson = true;
        let params = {
            'keyword': data,
            'latitude': this.latitude,
            'longitude': this.longitude,
            'page_no': 1,
            'limit': 15
        };
        this.appService.post(urls.programSearch, params)
            .subscribe(
                (response: any) => {
                    this.showNoResultFound = false;
                    if (response && response.sc === 1) {
                        this.searchArray = [];
                        if (response.result.program && response.result.program.length > 0) {
                            response.result.program.forEach((ele, ind) => {
                                let obj = {
                                    'name': ele.program_name,
                                    'id': ele._id,
                                };
                                this.searchArray.push(obj);
                            });
                        } else {
                            this.showNoResultFound = true;
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    onSelectedProgram(event) {
        this.programDetail(event.id);
    }

    clearFilter() {
        this.mapFilterForm = this.formBuilder.group({
            season: [''],
            bucketList: [''],
            pType: [true],
            searchProgram: [''],
            search: [''],
            filter_program_type: this.formBuilder.array([]),
            bucket: this.formBuilder.array([]),
            days: [''],
            daysArr: this.formBuilder.array([]),
            time: [''],
            location: ['current'],
            radius: 10
        });
        this.currentLocation = "Current Location";
        this.keywords = [];
        this.selSeason = "";
        this.fetchLocation();
        this.mapFilter();
        this.filtercount = "4";
        this.filterCount = 4;
        this.getProgramList();
    }
    clickEvent() {
        this.mode = document.getElementById("keydrop").classList.toggle("show");
    }
    increase(season){
        if(this.selSeason == ""){
            this.selSeason = season
            this.filterCount = this.filterCount + 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
        else{
            this.selSeason = season
        } 
    }
    markFavorite(programId, favType, i) {
        if (this.authService.isLoggednIn()) {
            let params = {
                'programId': programId,
                'isFav': (favType) ? false : true
            };
            this.appService.post(urls.programFavorite, params)
                .subscribe(
                    (response: any) => {
                        if (response.sc === 1) {
                            this.programArray[i].favorite = (favType) ? false : true;
                        }
                    });
        } else {
            this.getPopup('');
            this.router.navigate([""]);
        }
    }

    getPopup(test): void {
        this.popupService.showPopup(test);

    }
    getUserProfile() {
        if (this.isUserLoggedIn) {
            this.locationList = [];
            this.appService.get(urls.getProfile).subscribe(
                data => {
                    if (data['result'] && data['result'].user.role) {
                        this.currentRolee = data['result'].user.role;
                    }
                    if (data['result'] && data['result'].locations) {
                        let arr = data['result'].locations;
                        for (let i = 0; i < arr.length; i++) {
                            if (arr[i].address_type != 2) {
                                this.locationList.push(arr[i]);
                                this.cd.detectChanges();
                            }
                        }
                    }
                    this.cd.detectChanges();
                }, error => {
                    console.log('error', error);
                }
            )
        }
    }

    isclicked() {
        this.isClicked = true;
    }

    fetchLocation() {
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                position => {
                    let data = {
                        lat: position.coords.latitude,
                        long: position.coords.longitude
                    };
                    this.latitude = JSON.stringify(data.lat);
                    this.longitude = JSON.stringify(data.long);
                },
                error => {
                    this.latitude = defaultLocation.latitude;
                    this.longitude = defaultLocation.longitude;
                },
                {
                    enableHighAccuracy: true
                }
            );
        }
    }

    showBuckets(ele) {
        let buckets = '';
        for (let i = 0; i < ele.buckets.length; i++) {
            buckets = buckets + ele.buckets[i].name + ', ';
        }
        if (buckets && buckets.length)
            buckets = buckets.slice(0, -2);
        return buckets;
    }

    opendropdown() {
        this.modal = document.getElementById("myDropdown").classList.toggle("show");
    }

    setLocation(value, ref: any) {
        this.locIndex = ref;
        if (value == "current") {
            this.currentLocation = 'Current Location'
            this.fetchLocation();
        }
        else {
            let obj = value;
            this.latitude = obj.lat;
            this.longitude = obj.long;
            this.currentLocation = obj.address;
        }
        this.modal = document.getElementById("myDropdown").classList.remove("show");
    }

    saveLocation() {
        this.modal = document.getElementById("myDropdown").classList.toggle("show");
    }

    saveLocations() {
        if (!this.isUserLoggedIn) {
            this.getPopup('onboarding');
            this.popupVisibilityChange();
        }
        else {
            this.getPopup('addLocations');
            this.popupVisibilityChange();
        }
    }
    popupVisibilityChange() {
        return this.popupService.popupVisibilityChange.subscribe(value => {
            if (this.popupService.isPopupVisible) {
                this.popupService.togglePopupVisibility(false);
                this.getUserProfile();
            }

        });
    }
}
