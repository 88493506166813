import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../../shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PopupService } from '../../../../core/services/popup.service';
import { environment } from './../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public submitted = false;
  public token: any;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public hoverMeImg: number = null;
  public hoverMeImg1: number = null;
  public msgDisplay: string = "Password must contain 8 characters, at least 1 number, and a special character(!@#$%^&*)";

  constructor(private formBuilder: FormBuilder,
    private appService: AppService,
    private toastr: ToastrService,
    private router: Router,public translate: TranslateService,
    private popupService: PopupService) {
    this.token = this.router.url.split("/").pop()
  }

  ngOnInit() {
    window.scroll(0, 0)
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8),
      Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^#&])[A-Za-z\d@$!%*^#&]{8,}$/)]],
      confirmPassword: ['', [Validators.required]],
    }, { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') });
    this.appService.minHeightGlobally();
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        return passwordConfirmationInput.setErrors(null);
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPasswordForm.controls; }

  onSubmit(params) {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      let obj = {
        "password": params.password,
        "token": this.token
      };
      this.appService.post(urls.passwordReset, obj)
        .subscribe(
          (response: any) => {
            if (response && response.sc == 1) {
              this.appService.showSuccess(response.result.message);
              this.checkDevice()
            } else {
              this.appService.showError(response.error.msg);
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
  }

  hoverMe(val?) {
    if (val) {
      this.hoverMeImg = val
    }
    else {
      this.hoverMeImg = null
    }
  }

  hoverMe1(val?) {
    if (val) {
      this.hoverMeImg1 = val
    }
    else {
      this.hoverMeImg1 = null
    }
  }

  getPopup(popupType): void {
    this.popupService.showPopup(popupType);

  }
  checkDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      console.log("True");
      this.openApp(navigator.userAgent);
      setTimeout(this.fallbackToWebsite, 700);
      return true;
    }
    else {
      console.log("False");
      this.fallbackToWebsite();
      return false;
    }
  }
  fallbackToWebsite() {
    this.router.navigate(["/"]);
  };

  openApp(os) {
    if (os.match(/Android/i)) {
      document.location.href = environment.androidLink;
    }
    else {
      document.location.href = environment.deviceLink;
    }
  };
}