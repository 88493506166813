// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://api.spotivity.com/spotivity/1.1/api/1.1',
  //url :'https://stag.spotivity.com/spotivity/1.1/api/1.1',
  siteUrl: 'https://web.spotivity.com/',
  env: 'development',
  fbId: '537400383381485',
  instaId:'c0c811fc613e4b1085deab20739a0bf1',
  dropId:'dvm66lj4vwds4vs',
  linkId:'78f6wyd40622a4',
  twitKey:'AXcudxy0yU5T3tBzho7iCotZ3cnetKwcTIRlX0iwRl0',
  googleClientId:'982335608689-346acv0kcep4oqu0qvtmkebf8g8gej3e.apps.googleusercontent.com',
  androidLink:'https://spotivity.app.link',
  deviceLink:'com.spotivity://',
  redirectLinkAgencie :'https://web.spotivity.com/user/linked-agencies',
  googleDriveLink: 'https://www.googleapis.com/auth/drive',
  facebookSharePost: 'https://web.spotivity.com/social-share',
  youtubeEmbedLink:'https://youtube.com/embed/',
  youtubeWatchLink:'https://www.youtube.com/watch?v=',
  youtubeLink:'https://youtu.be',
  mapKey: 'AIzaSyCM5L5ytrC455Ns3tSjwPKxY389npMYzt4',
  firebase : {
    apiKey: "AIzaSyCM5L5ytrC455Ns3tSjwPKxY389npMYzt4",
    authDomain: "spotivity-211808.firebaseapp.com",
    databaseURL: "https://spotivity-211808.firebaseio.com",
    projectId: "spotivity-211808",
    storageBucket: "spotivity-211808.appspot.com",
    messagingSenderId: "982335608689",
    appId: "1:982335608689:web:e4cdced7c08d8d02f1c8d0",
    measurementId: "G-QCEV5ZHTSB"
  },
  agencySignupLink:'https://director.spotivity.com/createAccount'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.