import {Component, OnInit} from '@angular/core';
import {urls} from '../../../../shared/services/constant';
import {ApiParams, ApiResponse} from "../../../../shared/model/apiResponse";
import {AppService} from "../../../../shared/services/app.service";
import {ActivatedRoute, Router} from "@angular/router";
import {of as observableOf} from 'rxjs';
import {map, filter, throttleTime} from 'rxjs/operators';
import {fromEvent} from 'rxjs';
import { PopupService } from 'src/app/core/services/popup.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-programlist',
    templateUrl: './programlist.component.html',
    styleUrls: ['./programlist.component.scss']
})
export class ProgramlistComponent implements OnInit {
    public showFiler: boolean = false;
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public latitude: string;
    public longitude: string;
    public programArray: any = [];
    public searchArray: any = [];
    public season: any;
    public springSeason: string = '';
    public winterSeason: string = '';
    public fallSeason: string = '';
    public summerSeason: string = '';
    public resultsScrollObservable: any;
    public scrollSubscription: any;
    public page_no: number = 1;
    public lastElementData: any;
    public program_type: number = 3;
    public premium_type: any = 2;
    public lasTimestamp: number = 0;
    public faIconClass: string = 'fa fa-caret-right';
    public showNextData = true;
    public bucketArray: any = [];
    public checkedBucket: any = [];
    public checkedProgram: any = [];
    public checkedDays: any = [];
    public showNoResultFound: boolean = false;
    public seasonData: any = '';
    public timeData: any = '';
    public hideProgramUl: boolean = false;
    public type: any = '';
    public locationList: any = [];
    public isClicked: boolean = false;
    private bucketId: string = '';
    public programData: any = [];
    private programArr: any = [];
    private isUserLoggedIn: boolean = true;
    public navigationFrom: any;
    public showLoader: boolean;
    constructor(private appService: AppService,
                private router: Router,public translate:TranslateService,
                private popupService: PopupService,
                private activatedRoute: ActivatedRoute) {
                    this.showLoader = false;
                    
        this.activatedRoute.queryParams.subscribe(params => {
            this.bucketId = params['id'];
        })
        this.activatedRoute.queryParams.subscribe(params => {
            this.type = params['type'];
            if (this.type === 'refersh') {
                let clean_uri = location.protocol + '//' + location.host + location.pathname;
                window.history.replaceState({}, document.title, clean_uri);
                this.page_no = 1;
                this.lasTimestamp = 0;
                this.premium_type = 2;
                this.programArray = [];
            }
        });
    }

    ngOnInit() {
        window.scrollTo(0, 0);
        this.latitude = localStorage.getItem('lat');
        this.longitude = localStorage.getItem('lng')
    observableOf(null).subscribe(() => {
        this.getProgramList(this.program_type,this.premium_type);
  
    });
  
      this.resultsScrollObservable = fromEvent(window, 'scroll');
      this.scrollSubscription = this.resultsScrollObservable
        .pipe(
          throttleTime(250),
          map((event: any) => {
  
            console.log(event)
            if (event) {
              const elem = event.srcElement ? event.srcElement : event.target;
              const srcElement = elem.body;
              const clientHeight = srcElement ? srcElement.clientHeight : 0;
              const el = document.scrollingElement || document.documentElement;
              const scrollTop = el.scrollTop;
              const position = scrollTop + clientHeight;
              const height = srcElement ? srcElement.scrollHeight : 0;
              const positionPercent = +((position / height).toFixed(2));
              return positionPercent > .85;
            }
          }),
          filter((doSearch: boolean) => doSearch)
        )
        .subscribe((doSearch: boolean) => {
          if (this.showNextData) {
            this.page_no = this.page_no + 1;
            if(this.programData && this.programData.programList){
                let length = this.programData.programList.length-1
                this.program_type = this.programData.programList[length].program_type
                this.premium_type = this.programData.programList[length].paid ? this.programData.programList[length].paid : this.programData.programList[length].free
            }
            
            this.getProgramList(this.program_type,this.premium_type);
          }
        });
        this.isLoggedIn();
    }
    ngOnDestroy(){
      this.scrollSubscription.unsubscribe();
    }
    getProgramList(program_type,premium_type) {
        this.apiParam.appJson = true;
        let params = {
            'latitude': this.latitude,
            'longitude': this.longitude,
            'timestamp': this.lasTimestamp,
            'limit': 10,
            'program_type': program_type,
            'page_no': this.page_no,
            'premium_type': premium_type,
            bucket_id: this.bucketId
        };
        this.showLoader = true;
        this.appService.post(urls.programHomeList, params)
            .subscribe(
                (response: any) => {
                    this.showLoader = false;
                    this.showNextData = false;
                    if (response['result'] && response['result'].home && response['result'].home.length) {
                        this.programData = response['result'].home[0];
                        let ProgramData: any = response['result'].home[0].programList;
                        if (ProgramData && ProgramData.length > 0) {
                            ProgramData.forEach((ele, ind) => {
                                this.programArray.push(ele);
                            });
                            this.lastElementData = ProgramData[ProgramData.length - 1];
                            this.program_type = this.lastElementData.program_type;
                            this.premium_type = (this.lastElementData.free === 0) ? this.lastElementData.paid : this.lastElementData.free;
                            this.lasTimestamp = this.lastElementData.created_at;
                            this.showNextData = true;
                        }
                    }
                },
                (error) => {
                    this.showLoader = false;
                    console.log(error);
                }
            );
    }

    showSeason(ele) {
        let a = '';
        let b = '';
        let c = '';
        let d = '';
        if (ele.spring_season === 1) {
            a = 'Spring, ';
        }
        if (ele.winter_season === 1) {
            b = 'Winter, ';
        }
        if (ele.fall_season === 1) {
            c = 'Fall, ';
        }
        if (ele.summer_season === 1) {
            d = 'Summer,';
        }
        let season = '';
        season = a + b + c + d;
        if (d && d.length)
            season = season.slice(0, -1);
        else
            season = season.slice(0, -2);
        return season;
    }

    isLoggedIn() {
        let user = localStorage.getItem('LoggedIn');
        if (user && user.length) {
            this.isUserLoggedIn = true
        }
        else {
            this.isUserLoggedIn = false
        }
    }

    programDetail(id) {
        if (this.isUserLoggedIn){
        this.navigationFrom = "home"
        this.router.navigate(['program/program-detail/' + id], {queryParams: {nav_from: this.navigationFrom}});
    }
        else{
            this.getPopup('onboarding');
            this.popupService.popupVisibilityChange.subscribe(value => {
                if(this.popupService.isPopupVisible){
                  this.popupService.togglePopupVisibility(false);
                  this.router.navigate(['/']);
                }
    
              });
        }
    }

    getPopup(test): void {
        this.popupService.showPopup(test);

    }

    showBuckets(ele) {
        let buckets = '';
        for (let i = 0; i < ele.buckets.length; i++) {
            buckets = buckets + ele.buckets[i].name + ', ';
        }
        if (buckets && buckets.length)
            buckets = buckets.slice(0, -2);
        return buckets;
    }

}
