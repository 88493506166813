import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { AppService } from '../../../../shared/services/app.service';
import { urls, defaultLocation } from '../../../../shared/services/constant';
import { mapStyle, clusterStyle } from '../../../../shared/services/mapStyle';
import { HttpCancelService } from '../../../../shared/services/httpcancel.service';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, FormArray } from "@angular/forms";
import { PopupService } from "../../../../core/services/popup.service";
import { ToastrService } from 'ngx-toastr';

import { AgmInfoWindow } from '@agm/core';
// import Auth from '@aws-amplify/auth';
// import { Analytics } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';

// const amplifyConfig = {
//     Auth: {
//         identityPoolId: 'us-east-1:280abb4f-8eea-4a15-a64c-fb313fcc326e',
//         region: 'us-east-1'
//     }
// }

//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
//     AWSPinpoint: {
//         appId: '2e2afc99918a42f9bf32ea1e8b0a164c',
//         region: 'us-east-1',
//         mandatorySignIn: false,
//     }
// }

// Analytics.configure(analyticsConfig)

@Component({
    selector: 'app-map-list',
    templateUrl: './map-list.component.html',
    styleUrls: ['./map-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MapListComponent implements OnInit {
    public programArray: any = [];
    public newArray: any = [];
    public markerImage: string;
    public latitude: any;
    public longitude: any;
    public type: any = '';
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public isHidden = false;
    public modal: any;
    public mode: any;
    public locIndex: any;
    // just copying
    public currentRolee: any;
    public showFiler: boolean = false;
    public searchArray: any = [];
    public season: any;
    public page_no: number = 1;
    public page_size: number = 500;
    public lastElementData: any;
    public program_type: number = 3;
    public premium_type: any = 2;
    public lasTimestamp: number = 0;
    public faIconClass: string = 'fa fa-search';
    public showNextData = true;
    public bucketArray: any = [];
    public checkedBucket: any = [];
    public checkedProgram: any = [];
    public checkedDays: any = [];
    public showNoResultFound: boolean = false;
    public seasonData: any = '';
    public hideProgramUl: boolean = false;
    public locationList: any = [];
    public isUserLoggedIn: boolean = true;
    public currentLocation: string = "Current Location";
    public viewLoading: boolean = false;
    public zoom: number = 9;
    public programImage: any;
    public trainingSeason: any = '';
    public buckets: any = '';
    public lat: any;
    public lng: any;
    public minClusterSize = 30;
    public maxZoom = 15;
    public mark: any = [];
    public mapDesign: any;
    public clusterDesign: any;
    public previous: AgmInfoWindow;
    public newArray1: any = [];
    public newLat: any;
    public newLng: any;
    public radius: any = 10;
    public lastZoomLevel: any = 9;
    public storeZoomLevel: any = [];
    public cancelCurrentRequest: any;
    public filter: boolean = false;
    public value: number = 10;
    public options: Options = {
        floor: 0,
        ceil: 100,
        showSelectionBarFromValue: 0
    };
    public skip = 1;
    public skipS = 1;
    public limit = 10;
    public keywordsArr = [];
    public keywords: any = [];
    public timeout: any = null;
    public searchKey: string = '';
    public show: boolean = false;
    public filterCount:number = 2;
    public filtercount:string = "2";
    public selSeason:string = "";
    constructor(private appService: AppService,
        private activatedRoute: ActivatedRoute,
        private router: Router, private toastr: ToastrService, private cd: ChangeDetectorRef,public translate: TranslateService,
        private formBuilder: FormBuilder,
        private popupService: PopupService,
        private httpCancelService: HttpCancelService) {
        window.scroll(0, 0)
        if (localStorage.getItem("lat") && localStorage.getItem("lng")) {
            this.lat = Number(localStorage.getItem("lat"));
            this.lng = Number(localStorage.getItem("lng"));
        } else {
            this.lat = defaultLocation.latitude
            this.lng = defaultLocation.longitude
        }
        this.activatedRoute.queryParams.subscribe(params => {
            this.type = params['type'];
            if (this.type == 'refersh') {
                let clean_uri = location.protocol + "//" + location.host + location.pathname;
                window.history.replaceState({}, document.title, clean_uri);
            }
        });
    }
    mapFilterForm: FormGroup;

    ngOnInit() {
        // Analytics.record({ name: 'Explore Visited' });
        this.fetchLocation();
        this.mapFilterForm = this.formBuilder.group({
            season: [''],
            bucketList: [''],
            pType: [true],
            searchProgram: [''],
            search: [''],
            filter_program_type: this.formBuilder.array([]),
            bucket: this.formBuilder.array([]),
            days: [''],
            daysArr: this.formBuilder.array([]),
            time: [''],
            radius: 10,
            location: ['current'],
        });
        this.programChange('1', true);
        this.programChange('2', true);
        this.isLoggedIn();
        this.latitude = this.lat
        this.longitude = this.lng

        this.getProgramBylat(this.page_no, this.page_size, this.latitude, this.longitude);
        this.mapDesign = mapStyle;
        this.clusterDesign = clusterStyle
        this.cd.detectChanges();
    }
    viewDetail(id) {
        if (this.isUserLoggedIn) {
            this.router.navigate(['/program/program-detail/' + id]);
        }
        else {
            this.getPopup('onboarding');
            this.popupService.popupVisibilityChange.subscribe(value => {
                if (this.popupService.isPopupVisible) {
                    this.popupService.togglePopupVisibility(false);
                    this.router.navigate(['/']);
                }
            });
        }
    }
    getProgramBylat(page, page_size, latitude, longitude) {

        this.viewLoading = true;
        this.apiParam.appJson = true;
        this.latitude = latitude;
        this.longitude = longitude;
        let params = {
            'program_type': this.program_type,
            'bucket': this.checkedBucket,
            'filter_program_type': this.checkedProgram,
            'season': this.seasonData,
            'page': page,
            'page_size': page_size,
            'sort': 1
        };
        params['latitude'] = latitude;
        params['longitude'] = longitude;
        params['radius'] = this.radius;
        if (this.checkedDays && this.checkedDays.length) {
            params['daysOfWeek'] = this.checkedDays;
        }
        if(this.keywords && this.keywords.length){
            params['keywords'] = this.keywords
        }
        this.apiParam.params = params;
        this.apiParam.appJson = true;
        this.cancelCurrentRequest = this.appService.post(urls.listBylatlng, this.apiParam.params)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        if (response.result.program && response.result.program.length > 0) {
                            const a = 360.0 / response.result.program.length;
                            response.result.program.forEach((ele, ind, arr) => {
                                //  console.log("ind", ind)
                                if (ele.latlng) {
                                    this.newLat = ele.latlng[1] + -.0004 * Math.cos((+a * ind) / 180 * Math.PI);
                                    this.newLng = ele.latlng[0] + -.0004 * Math.sin((+a * ind) / 180 * Math.PI);
                                }
                                
                                const bucketImage = ele.buckets.length > 0 ? ele.buckets[0].image : '';
                                let programImage = 'assets/img/map-view-default.png';
                                if (ele.image__url != "" && ele.image__url != null) {
                                    this.programImage = ele.image__url
                                }
                                else {
                                    this.programImage = programImage
                                }
                                let freeImage = ele.buckets.length > 0 ? ele.buckets[0].freeImage : './assets/img/m1.png';
                                let premiumImage = ele.buckets.length > 0 ? ele.buckets[0].premiumImage : './assets/img/m2.png';
                                if (ele.free == 1) {
                                    this.markerImage = freeImage
                                }
                                else {
                                    this.markerImage = premiumImage
                                }
                                this.trainingSeason = '';
                                if (ele.spring_season && ele.spring_season === 1) {
                                    this.trainingSeason = this.trainingSeason + 'Spring, ';
                                }
                                if (ele.winter_season && ele.winter_season === 1) {
                                    this.trainingSeason = this.trainingSeason + 'Winter, ';
                                }
                                if (ele.fall_season && ele.fall_season === 1) {
                                    this.trainingSeason = this.trainingSeason + 'Fall, ';
                                }
                                if (ele.summer_season && ele.summer_season === 1) {
                                    this.trainingSeason = this.trainingSeason + 'Summer, ';
                                }
                                this.trainingSeason = this.trainingSeason.slice(0, -2);
                                let image = './assets/img/fav-icon.png';
                                if (ele.buckets[0] && ele.buckets[0].image !== '') {
                                    image = ele.buckets[0].image;
                                }
                                this.buckets = '';
                                for (let i = 0; i < ele.buckets.length; i++) {
                                    this.buckets = this.buckets + ele.buckets[i].name + ', ';
                                }
                                if (this.buckets && this.buckets.length)
                                    this.buckets = this.buckets.slice(0, -2);
                                let body = {
                                    '_id': ele._id,
                                    'lat': this.newLat,
                                    'lng': this.newLng,
                                    'program_name': ele.program_name,
                                    'trainingSeason': this.trainingSeason,
                                    'agency_name': ele.agency_name,
                                    'profile_pic': this.programImage,
                                    'bucket_image': bucketImage,
                                    'buckets': this.buckets,
                                    'address': ele.address,
                                    'markerImage': this.markerImage
                                }

                                if (this.programArray.filter(item => item._id == body._id).length == 0) {
                                    this.programArray.push(body);
                                }
                            });
                            this.getProgramBylat(page + 1, 500, latitude, longitude)

                        } else {
                            this.viewLoading = false;
                        }
                        this.cd.detectChanges();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );

    }

    get f() {
        return this.mapFilterForm.controls;
    }
    showError(msg) {
        const toast = document.getElementById('toast-container');
        if (toast) {
          toast.innerHTML = '';
        }
        this.toastr.info(msg, '', {
          timeOut: 1000
        });
      }
    programChange(ptype: string, isChecked: boolean) {
        const programFormArray = <FormArray>this.mapFilterForm.controls.filter_program_type;

        if (isChecked) {
            programFormArray.push(new FormControl(ptype));
            this.filterCount = this.filterCount + 1
            if(this.filterCount <= 10){
              this.filtercount = this.filterCount.toString();
            }
            else if(this.filterCount > 10 && this.filterCount < 20){
                this.filtercount = "10+"
            }
            else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
            else if(this.filterCount > 20){
                this.filtercount = "20+"
            }
            this.cd.detectChanges();
        } else {
            let index = programFormArray.controls.findIndex(x => x.value === ptype);
            programFormArray.removeAt(index);
            this.filterCount = this.filterCount - 1;
            if(this.filterCount < 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }

    dayChange(dtype: any, isChecked: boolean) {
        const dayFormArray = <FormArray>this.mapFilterForm.controls.daysArr;
        if (isChecked) {
            dayFormArray.push(new FormControl(dtype));
            this.filterCount = this.filterCount + 1
            if(this.filterCount < 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
            this.cd.detectChanges();
        } else {
            let index = dayFormArray.controls.findIndex(x => x.value === dtype);
            dayFormArray.removeAt(index);
            this.filterCount = this.filterCount - 1
            if(this.filterCount < 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }

    bucketChange(btype: string, isChecked: boolean) {
        const bucketFormArray = <FormArray>this.mapFilterForm.controls.bucket;
        if (isChecked) {
            bucketFormArray.push(new FormControl(btype));
            this.filterCount = this.filterCount + 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
            this.cd.detectChanges();
        }
        else {
            let index = bucketFormArray.controls.findIndex(x => x.value == btype)
            bucketFormArray.removeAt(index);
            this.filterCount = this.filterCount - 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }

    bucketFirstChange(btype: string, isChecked: boolean) {
        const bucketFormArray = <FormArray>this.mapFilterForm.controls.bucket;
        if (isChecked) {
            bucketFormArray.push(new FormControl(btype));
            this.cd.detectChanges();
        }
    }
    selectMarker(infowindow) {
        if (this.previous) {
            this.previous.close();
        }
        this.previous = infowindow;
    }
    onSubmit(params) {
        this.viewLoading = true;
        let data = Object.assign({}, params);
        this.checkedDays = data.daysArr;
        this.checkedBucket = data.bucket;
        this.checkedProgram = data.filter_program_type;
        if (this.checkedProgram.length > 1 || this.checkedProgram[0] === '2') {
            this.premium_type = 2;
        } else {
            this.premium_type = 1;
        }
        this.seasonData = data.season;
        this.page_no = 1;
        this.program_type = 3;
        this.programArray = [];
        this.radius = this.value;
        this.filter = true;
        this.getProgramBylat(this.page_no, this.page_size, this.latitude, this.longitude);
    }
    increase(season){
        if(this.selSeason == ""){
            this.selSeason = season
            this.filterCount = this.filterCount + 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
        else{
            this.selSeason = season
        } 
    }
    getBucketList() {
        let params = {};
        this.apiParam.appJson = true;
        this.appService.post(urls.listBucket, params)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        this.bucketArray = [];
                        if (response.result.bucket && response.result.bucket.length > 0) {
                            response.result.bucket.forEach((ele, ind) => {
                                let obj = {
                                    'icon': ele.icon,
                                    'image': ele.image,
                                    'name': ele.name,
                                    'id': ele._id,
                                };
                                this.bucketArray.push(obj);
                                this.cd.detectChanges();
                                this.viewLoading = false;
                            });
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    getKeywordList(skip, limit) {
        let params = {};
        this.apiParam.appJson = true;
        this.show = false;
        skip = skip ? ((skip - 1) * 10) : skip;
        this.appService.get(urls.listKeyword + `?skip=${skip}&limit=${limit}`)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        if (response.result.keywords && response.result.keywords.length > 0) {
                            response.result.keywords.forEach((ele, ind) => {
                                let obj = {
                                    'name': ele.keyword,
                                    'id': ele._id,
                                };
                                this.keywordsArr.push(obj);
                                this.cd.detectChanges();
                                this.viewLoading = false;
                            });
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    checkForKeywords(keyword: any) {
        if (this.keywords.length > 0) {
            for (let i = 0; i <= this.keywords.length - 1; i++) {
                if (this.keywords[i] == keyword.name) {
                    return true;
                }
            }
        }
    }
    change(event, name) {
        if (event.target.checked == true) {
            if(this.keywords.length<5){
                this.keywords.push(name)
                this.filterCount = this.filterCount + 1
                if(this.filterCount <= 10){
                    this.filtercount = this.filterCount.toString();
                  }
                  else if(this.filterCount > 10 && this.filterCount < 20){
                      this.filtercount = "10+"
                  }
                  else if(this.filterCount == 20){
                    this.filtercount = this.filterCount.toString();
                  }
                  else if(this.filterCount > 20){
                      this.filtercount = "20+"
                  }
            }
            else{
             event.target.checked = false;
             this.appService.showInfo('Only 5 keywords can be applied on filters')
            }      
        }
        else {
            const index = this.keywords.indexOf(name);
            this.keywords.splice(index, 1);
            this.filterCount = this.filterCount - 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }
    removeKeyword(name){
        const index = this.keywords.indexOf(name);
        this.keywords.splice(index, 1);
        this.filterCount = this.filterCount - 1
        if(this.filterCount <= 10){
            this.filtercount = this.filterCount.toString();
          }
          else if(this.filterCount > 10 && this.filterCount < 20){
              this.filtercount = "10+"
          }
          else if(this.filterCount == 20){
            this.filtercount = this.filterCount.toString();
          }
          else if(this.filterCount > 20){
              this.filtercount = "20+"
          }
    }
    loadAllOnScroll(event) {
        const elem = event.target;
        if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
            if (this.searchKey == '') {
                this.skip = this.skip + 1;
                this.getKeywordList(this.skip, this.limit);
            }
            else {
                this.skipS = this.skipS + 1;
                this.searchKeyword(this.skipS, this.limit, this.searchKey)
            }
        }
        else{

        }
    }
    searchKeyword(skip, limit, keyword) {
        let params = {};
        this.apiParam.appJson = true;
        skip = skip ? ((skip - 1) * 10) : skip;
        this.appService.get(urls.listKeyword + `?skip=${skip}&limit=${limit}&name=${keyword}`)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        this.show = false;
                        if (response.result.keywords && response.result.keywords.length > 0) {
                            response.result.keywords.forEach((ele, ind) => {
                                let obj = {
                                    'name': ele.keyword,
                                    'id': ele._id,
                                };
                                this.keywordsArr.push(obj);
                                this.cd.detectChanges();
                                this.viewLoading = false;
                            });
                        }
                        else {
                            if (this.keywordsArr.length == 0) {
                                this.show = true;
                            }
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    onKey(event) {
        this.searchKey = event
        clearTimeout(this.timeout);
        this.keywordsArr = [];
        var $this = this;
        this.timeout = setTimeout(function () {
            if (event.keyCode != 13) {
                this.mode = document.getElementById("keydrop").classList.add("show");
                if (event.length > 2) {
                    this.skipS = 1;
                    this.limit = 10;
                    $this.searchKeyword(this.skipS, this.limit, event)
                }
                else {
                    this.skip = 1;
                    this.limit = 10;
                    $this.getKeywordList(this.skip, this.limit);
                }
            }
        }, 1000);
    }
    onSearchChange(data) {
        this.showNoResultFound = false;
        if (data.target.value.length >= 3) {
            this.searchProgram(data.target.value);
        } else {
            this.searchArray = [];
            this.hideProgramUl = false;
            this.cd.detectChanges();
        }
    }

    searchProgram(data) {
        this.hideProgramUl = true;
        this.apiParam.appJson = true;
        let params = {
            'keyword': data,
            'latitude': this.latitude,
            'longitude': this.longitude,
            'page_no': 1,
            'limit': 15
        };
        this.appService.post(urls.programSearch, params)
            .subscribe(
                (response: any) => {
                    this.showNoResultFound = false;
                    if (response && response.sc === 1) {
                        this.searchArray = [];
                        if (response.result.program && response.result.program.length > 0) {
                            response.result.program.forEach((ele, ind) => {
                                let obj = {
                                    'name': ele.program_name,
                                    'id': ele._id,
                                };
                                this.searchArray.push(obj);
                                this.cd.detectChanges();
                            });
                        } else {
                            this.showNoResultFound = true;
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    onSelectedProgram(event) {
        this.programDetail(event.id);
    }

    programDetail(id) {
        if (this.isUserLoggedIn)
            this.router.navigate(['program/program-detail/' + id]);
        else
            this.getPopup('onboarding')
    }

    isLoggedIn() {
        let user = localStorage.getItem('LoggedIn');
        if (user && user.length) {
            this.isUserLoggedIn = true
        }
        else {
            this.isUserLoggedIn = false
        }
    }

    clearFilter() {
        this.mapFilterForm = this.formBuilder.group({
            season: [''],
            bucketList: [''],
            pType: [true],
            searchProgram: [''],
            search: [''],
            filter_program_type: this.formBuilder.array([]),
            bucket: this.formBuilder.array([]),
            days: [''],
            daysArr: this.formBuilder.array([]),
            time: [''],
            location: ['current'],
            radius: 10
        });
        this.checkedBucket = '';
        this.checkedProgram = '';
        this.seasonData = '';
        this.keywords = [];
        this.checkedDays = [];
        this.currentLocation = "Current Location";
        this.selSeason = "";
        this.fetchLocation();
        this.mapFilter();
        this.filter = false;
        this.filtercount = "4";
        this.filterCount = 4;
        this.getProgramBylat(this.page_no, this.page_size, this.latitude, this.longitude);
    }

    getPopup(test): void {
        this.popupService.showPopup(test);
    }
    clickEvent() {
        this.mode = document.getElementById("keydrop").classList.toggle("show");
    }
    getUserProfile() {
        if (this.isUserLoggedIn) {
            this.locationList = [];
            this.appService.get(urls.getProfile).subscribe(
                data => {
                    if (data['result'] && data['result'].user.role) {
                        this.currentRolee = data['result'].user.role;
                    }
                    if (data['result'] && data['result'].locations) {
                        let arr = data['result'].locations;
                        for (let i = 0; i < arr.length; i++) {
                            if (arr[i].address_type != 2) {
                                this.locationList.push(arr[i]);
                                this.cd.detectChanges();
                            }
                        }
                    }
                    this.cd.detectChanges();
                }, error => {
                    console.log('error', error);
                }
            )
        }
    }

    fetchLocation() {
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                position => {
                    let data = {
                        lat: position.coords.latitude,
                        long: position.coords.longitude
                    };
                    this.latitude = JSON.stringify(data.lat);
                    this.longitude = JSON.stringify(data.long);
                },
                error => {
                    this.latitude = defaultLocation.latitude;
                    this.longitude = defaultLocation.longitude;
                },
                {
                    enableHighAccuracy: true
                }
            );
        }
    }

    mapFilter() {
        if (this.faIconClass === 'fa fa-search' && this.showFiler == false) {
            this.faIconClass = 'fa fa-search';
            if (this.bucketArray.length == 0) {
                this.viewLoading = true;
                this.getBucketList();
                this.getKeywordList(this.skip, this.limit);
                this.getUserProfile();
            }
        } else {
            this.faIconClass = 'fa fa-search';
        }
        this.showFiler = !this.showFiler;
    }

    opendropdown() {
        this.modal = document.getElementById("myDropdown").classList.toggle("show");
    }

    setLocation(value, ref: any) {
        this.locIndex = ref;
        if (value == "current") {
            this.currentLocation = 'Current Location'
            this.fetchLocation();
        }
        else {
            let obj = value;
            this.latitude = obj.lat;
            this.longitude = obj.long;
            this.currentLocation = obj.address;
        }
        this.modal = document.getElementById("myDropdown").classList.remove("show");
    }

    saveLocation() {
        this.modal = document.getElementById("myDropdown").classList.toggle("show");
    }

    saveLocations() {
        if (!this.isUserLoggedIn) {
            this.getPopup('onboarding');
            this.popupVisibilityChange();
        }
        else {
            this.getPopup('addLocations');
            this.popupVisibilityChange();
        }
    }

    popupVisibilityChange() {
        return this.popupService.popupVisibilityChange.subscribe(value => {
            if (this.popupService.isPopupVisible) {
                this.popupService.togglePopupVisibility(false);
                this.getUserProfile();
            }

        });
    }

    mapReady(map) {
        var that = this;    //this.map = map;
        map.addListener("dragend", () => {
            let bounds = map.getBounds();
            var ne = bounds.getNorthEast(); // LatLng of the north-east corner
            var sw = bounds.getSouthWest();
            var c = map.getCenter(),
                x = c.lng(),
                y = c.lat(),
                maxX = bounds.getNorthEast().lng(),
                maxY = bounds.getNorthEast().lat(),
                minX = bounds.getSouthWest().lng(),
                minY = bounds.getSouthWest().lat();

            if (x < minX) x = minX;
            if (x > maxX) x = maxX;
            if (y < minY) y = minY;
            if (y > maxY) y = maxY;
            if (!that.storeZoomLevel.includes(0) && !that.storeZoomLevel.includes(1)) {
                that.getProgramBylat(that.page_no, that.page_size, y, x);
            }
        });
        map.addListener("zoom_changed", () => {
            if (that.lastZoomLevel < map.getZoom()) {
                that.radius = that.radius / 3;
            } else {
                that.radius = that.radius * 3;
            }
            let bounds = map.getBounds();
            var ne = bounds.getNorthEast(); // LatLng of the north-east corner
            var sw = bounds.getSouthWest();
            var c = map.getCenter(),
                x = c.lng(),
                y = c.lat(),
                maxX = bounds.getNorthEast().lng(),
                maxY = bounds.getNorthEast().lat(),
                minX = bounds.getSouthWest().lng(),
                minY = bounds.getSouthWest().lat();

            if (x < minX) x = minX;
            if (x > maxX) x = maxX;
            if (y < minY) y = minY;
            if (y > maxY) y = maxY;
            if (this.filter == true) {
                if (that.lastZoomLevel > map.getZoom()) {
                    that.storeZoomLevel.push(map.getZoom());
                    that.cancelCurrentRequest.unsubscribe();
                    that.getProgramBylat(that.page_no, that.page_size, y, x);
                }
            }
            else {
                if (that.lastZoomLevel > map.getZoom() && !that.storeZoomLevel.includes(map.getZoom())) {
                    that.storeZoomLevel.push(map.getZoom());
                    that.cancelCurrentRequest.unsubscribe();
                    that.getProgramBylat(that.page_no, that.page_size, y, x);
                }
            }

            that.lastZoomLevel = map.getZoom();
        });
    }
}
