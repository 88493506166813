import { Component, OnInit } from '@angular/core';
import { ApiParams, ApiResponse } from 'src/app/shared/model/apiResponse';
import { AppService } from 'src/app/shared/services/app.service';
import { of as observableOf } from 'rxjs';
import { map, filter, throttleTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { urls } from 'src/app/shared/services/constant';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-linked-agencies',
  templateUrl: './linked-agencies.component.html',
  styleUrls: ['./linked-agencies.component.scss']
})
export class LinkedAgenciesComponent implements OnInit {
  public viewLoading: boolean = false;
  public apiParam: ApiParams = new ApiParams();
  public apiResponse: ApiResponse = new ApiResponse();
  public page_no: number = 1;
  public pageSize: number = 10;
  public showNextData = true;
  public agencyList: any = [];
  public resultsScrollObservable: any;
  public scrollSubscription: any;
  public navFor:any;
  constructor(private appService: AppService, private router: Router, public translate: TranslateService,
    private route: ActivatedRoute) {
    window.scroll(0, 0);
    this.route.queryParams.subscribe(params =>{
      this.navFor = params.navFor
    })
    this.route.fragment.subscribe(params => {
      if (params) {
        let qParams: any = {};
        let queryP = params.split("&");
        queryP.forEach(function (queryStringVariable) {
          var __variable = queryStringVariable.split("=");
          qParams[__variable[0]] = __variable[1];
        });

        if (qParams['access_token']) {
          let formData = {
            token: qParams['access_token'],
            key: localStorage.getItem('key'),
            share_on: 2             // for dropbox
          };
          this.appService.post(urls.file_share, formData).subscribe(
            data => {
              this.viewLoading = false;
              window.close();
              if (data['sc'] == 1) {
                this.appService.showSuccess('Note Saved on Dropbox  Successfully');
              }
              if (data['sc'] == 0) {
                this.appService.showError(data['error'].msg);
              }
            }, error => {
              console.log('error', error);
            }
          )
        }
      }
    })
  }

  ngOnInit() {
    observableOf(null).subscribe(() => {
      window.scrollTo(0, 0);
      this.getLinkedAgencies();

    });

    this.resultsScrollObservable = fromEvent(window, 'scroll');
    /**
     * Captures scrolling behavior within teh application, so we can implement infinite
     *  scroll or hiding elements once a certain amount of scrolling is done.
     */
    this.scrollSubscription = this.resultsScrollObservable
      .pipe(
        throttleTime(250),
        map((event: any) => {
          if (event) {
            const elem = event.srcElement ? event.srcElement : event.target;
            const srcElement = elem.body;
            const clientHeight = srcElement ? srcElement.clientHeight : 0;
            const el = document.scrollingElement || document.documentElement;
            const scrollTop = el.scrollTop;
            const position = scrollTop + clientHeight;
            const height = srcElement ? srcElement.scrollHeight : 0;
            const positionPercent = +((position / height).toFixed(2));
            return positionPercent > .85;
          }
        }),
        filter((doSearch: boolean) => doSearch)
      )
      .subscribe((doSearch: boolean) => {
        if (this.showNextData) {
          this.page_no = this.page_no + 1;
          this.getLinkedAgencies();
        }
      });

  }
  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
  getLinkedAgencies() {
    this.viewLoading = true;
    this.apiParam.appJson = true;
    this.appService.get(urls.linkedAgencies + `?page_no=${this.page_no}&page_size=${this.pageSize}`)
      .subscribe(
        (response: any) => {
          this.viewLoading = false;
          this.showNextData = false;
          if (response['result'] && response['result'].myAgencies && response['result'].myAgencies.length) {
            let data: any = response['result'].myAgencies;
            if (data && data.length > 0) {
              data.forEach((ele, ind) => {
                this.agencyList.push(ele);
              });
            }
            this.showNextData = true
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  goToPrograms(id){
     this.router.navigate(['/agency-programs'],{queryParams:{id:id}})
  }
}

